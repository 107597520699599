import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { closeMenu, selectMenu } from "../store";

export default function () {
    const dispatch = useDispatch();
    const menu = useSelector(selectMenu);

    return (<>
        <div className={`mobile-menu top-0 left-0 absolute flex h-screen w-screen z-50 bg-background1 ${menu.isOpen ? "block" : "hidden"}`}>
            <div className="container mx-auto h-full flex flex-col">
                <div className="pt-3 pb-8 flex items-center justify-between">
                    <a href="">
                        <img src="../images/logo.svg" className="h-16" alt="logo"/>
                    </a>
                    <button className="mobile-menu__close-button" onClick={() => dispatch(closeMenu())}>
                        <i className="icon-cancel text-blue text-3xl"/>
                    </button>
                </div>
                <div className="flex-1 flex flex-col justify-between py-4">
                    <ul>
                        <a href="/#announce" onClick={() => dispatch(closeMenu())} slot="item" target=""><div className="text-blue text-xl mb-5">Анонс</div></a>
                        <a href="/#about" onClick={() => dispatch(closeMenu())} slot="item" target=""><div className="text-blue text-xl mb-5">О нас</div></a>
                        <a href="/#reviews" onClick={() => dispatch(closeMenu())} slot="item" target=""><div className="text-blue text-xl mb-5">Отзывы</div></a>
                        <a href="https://marriedandhappy.ru" onClick={() => dispatch(closeMenu())} slot="item" target="_blank"><div className="text-blue text-xl mb-5">Брачное агентство</div></a>
                    </ul>

                    <a href="/cabinet/list.php" className="border text-lg w-full  border-blue rounded px-12
                            py-3 text-blue hover:border-blue-light hover:text-blue-light
                            active:border-blue-saturated active:text-blue-saturated">ВОЙТИ</a>

                    <div className="flex">
                        <a href="https://vk.com/speed_dating_davaynasvidanie" className="mr-2 flex">
                            <i className="icon-vk text-blue text-2xl flex h-mincontent"/>
                        </a>
                        <a href="https://my.winwinbot.com/bot/1/marriedandhappy_bot" className="flex">
                            <img src="/images/tg-bot.jpg" className="flex w-12 h-12 rounded-md"/>
                        </a>
                    </div>

                    <div>
                        <a href="tel:+79266362046" className="text-blue text-xl">+7 (926) 636 20 46</a>
                        <p className="text-blue">© ДавайНАсвидание, {new Date().getFullYear()}</p>
                    </div>
                </div>
            </div>
        </div>
    </>);
}
