import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import SeoConfig, {pages} from "../components/seo"
import ErrorNotFound from "./ErrorNotFound";
import { selectSeoContext, setSeoContext } from "../store";
import { useSelector } from "react-redux";


export default function SEO() {
    const { alias } = useParams();

    const seoContext = useSelector(selectSeoContext);
    console.log(alias);
    console.log(SeoConfig);

    if (!seoContext) {
        return (<ErrorNotFound/>);
    }

    return (<>
        <Helmet>
            {seoContext.title ? <title>{seoContext.title}</title> : null}
            {seoContext.metakw ? <meta name="keywords" content={seoContext.metakw}/> : null}
            {seoContext.metadesc ? <meta name="description" content={seoContext.metadesc}/> : null}
        </Helmet>
        <div dangerouslySetInnerHTML={{ __html: seoContext.template }}/>
    </>);
}

SEO.preInitStore = async (store, requestUrl) => {
    if(pages[requestUrl] === undefined) {
        console.log("NOT FOUND requestUrl ");
        return;
    }
    store.dispatch(setSeoContext({...pages[requestUrl]}));
};