import { configureStore } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "./app.config";
import moment from 'moment';

const initialState = typeof window !== 'undefined' ? window.initialState : {};

const formatTel = tel => {
    let match;
    tel = tel.toString().trim();

    match = tel.match(/^8(\d{10})$/);
    if (match) {
        return `7${match[1]}`;
    }

    match = tel.match(/^\+?7(\d{10})$/);
    if (match) {
        return `7${match[1]}`;
    }

    return tel;
};

const rootReducer = (state, action) => {
    if (action.type === "SET_EVENTS") {
        return { ...state, events: action.payload };
    }

    if (action.type === "POPUP") {
        return { ...state, popup: action.payload };
    }

    if (action.type === "MENU") {
        return { ...state, menu: action.payload };
    }

    if (action.type === "SEO") {
        return { ...state, seo: action.payload };
    }

    if (action.type === "SUBMIT_EVENT") {
        return { ...state, submitEvent: action.payload };
    }

    if (action.type === "CALL_ME") {
        return { ...state, callMe: action.payload };
    }

    return state;
};
export default configureStore({ reducer: rootReducer, preloadedState: initialState });

export const fetchEvents = () => async dispatch => {
    console.log("fetching events...");
    try {
        const response = await axios.get(`${API_URL}speeddating/event`);
        return dispatch({ type: 'SET_EVENTS', payload: response.data.response });
    } catch (e) {
        console.error("Cannot fetch events: ", e);
    }
};

export const initPopup = () => async dispatch => {
    return dispatch({ type: "POPUP", payload: { isOpen: false, isForEvent: false, event: null } });
};

const weekdays = [ "Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота" ];
const months = [ 'Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября',
    'Ноября', 'Декабря',
];
export const ageGroupNames = [ 'МОЛОДАЯ ГРУППА', 'ОСНОВНАЯ ГРУППА', 'СТАРШАЯ ГРУППА', ' ГРУППА 50+', 'VIP-МЕРОПРИЯТИЯ' ];
const ddmmhhmm = (isoString) => {
    let d = new Date(isoString);
    let date = d.getDate();
    let month = months[d.getMonth()];

    return `${date} ${month} ${moment(isoString).utcOffset(3 * 60).format("HH:mm")}`;
};
export const selectEvents = state => {
    return state?.events?.map(evt => {
        const d = {
            __weekday: weekdays[(new Date(evt.date)).getDay()],
            __date_str: ddmmhhmm(evt.date),

            cafe_name: evt.speeddating_cafe.name,
            cafe_alias: evt.speeddating_cafe.alias,
            cafe_address: evt.speeddating_cafe.address,
            cafe_metro: evt.speeddating_cafe.metro,
            cafe_description: evt.speeddating_cafe.description,
            cafe_how_to_pass: evt.speeddating_cafe.how_to_pass,
            cafe_map: evt.speeddating_cafe.map,
            cafe_photos: evt.speeddating_cafe.photos.map(photo => (`z${photo.dir}/${photo.big}`)),

            id: evt.id,
            description: evt.description,
            age_group_text: ageGroupNames[evt.age_group - 1] ? ageGroupNames[evt.age_group - 1] : "",
            age_group: +evt.age_group,
            info: evt.info,
            price: `от ${Math.min(evt.price_m, evt.price_f)}`,
            old_price: `от ${Math.min(evt.old_price_m, evt.old_price_f)}`,
            min_age_f: evt.min_age_f,
            max_age_f: evt.max_age_f,
            min_age_m: evt.min_age_m,
            max_age_m: evt.max_age_m,
        };
        if (d.cafe_photos) {
            d.cafe_photo = d.cafe_photos[0];
        } else {
            d.cafe_photo = null;
        }

        return d;
    }) || [];
};

export const submitForm = data => async dispatch => {
    try {
        dispatch({
            type: 'SUBMIT_EVENT',
            payload: {
                status: 'loading',
                error: null,
                response: null,
            },
        });

        const response = await axios.post(`${API_URL}speeddating/event/${data.event_id}/register`, {
            name: data.name,
            tel: formatTel(data.tel),
            email: data.email.trim(),
            age: data.age,
            'g-recaptcha-response': data['g-recaptcha-response'],
        });

        return dispatch({
            type: 'SUBMIT_EVENT',
            payload: {
                status: 'done',
                error: null,
                response: response.data.response,
            },
        });

    } catch (e) {
        return dispatch({
            type: 'SUBMIT_EVENT',
            payload: {
                status: 'error',
                error: e.response && e.response.data ? e.response.data : e,
                response: null,
            },
        });
    }
};


export const callMe = data => async dispatch => {
    try {
        dispatch({
            type: 'CALL_ME',
            payload: {
                status: 'loading',
                error: null,
                response: null,
            },
        });

        const response = await axios.post(`${API_URL}speeddating/call_me`, {
            tel: formatTel(data.tel),
            'g-recaptcha-response': data['g-recaptcha-response'],
        });

        return dispatch({
            type: 'CALL_ME',
            payload: {
                status: 'done',
                error: null,
                response: response.data.response,
            },
        });

    } catch (e) {
        return dispatch({
            type: 'CALL_ME',
            payload: {
                status: 'error',
                error: e.response && e.response.data ? e.response.data : e,
                response: null,
            },
        });
    }
};

// POPUP

export const openPopupEvent = (event) => (dispatch) => {
    if (typeof document !== 'undefined') {
        document.body.classList.add('overflow-hidden');
    }

    return dispatch({
        type: "POPUP",
        payload: {
            isOpen: true,
            isForEvent: true,
            event: event,
        },
    });
};
export const closePopup = () => (dispatch) => {
    if (typeof document !== 'undefined') {
        document.body.classList.remove('overflow-hidden');
    }

    return dispatch({
        type: "POPUP",
        payload: {
            isOpen: false,
            isForEvent: false,
            event: null,
        },
    });
};

export const selectPopup = state => state?.popup || { isOpen: false, isForEvent: false, event: null };
export const selectSubmitEvent = state => state?.submitEvent || { status: 'idle', error: null, response: null };
export const selectCallMeRequest = state => state?.callMe || { status: 'idle', error: null, response: null };

// Seo

export const setSeoContext = data => dispatch => {
    return dispatch({
        type: "SEO",
        payload: data,
    });
};

export const selectSeoContext = state => state?.seo ? state.seo : null;

// Menu

export const openMenu = () => dispatch => {
    if (typeof document !== 'undefined') {
        document.body.classList.add('overflow-hidden');
    }

    return dispatch({
        type: "MENU",
        payload: {
            isOpen: true,
        },
    });
};

export const closeMenu = () => dispatch => {
    if (typeof document !== 'undefined') {
        document.body.classList.remove('overflow-hidden');
    }

    return dispatch({
        type: "MENU",
        payload: {
            isOpen: false,
        },
    });
};

export const selectMenu = state => state?.menu || { isOpen: false };
